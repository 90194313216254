import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

// 查询停车场信息
export function getDeviceList(params) {
    return request.post('/device/findDevice?' + objectToQueryString(params));
}

// 添加设备
export function saveDevice(params) {
    return request.post('/device/saveDevice?' + objectToQueryString(params));
}

// 删除设备
export function deleteDevice(params) {
    return request.post('/device/deleteDevice?' + objectToQueryString(params));
}

// 编辑设备
export function editDevice(params) {
    return request.post('/device/editDevice?' + objectToQueryString(params));
}

// 获取设备详情
export function getDeviceById(deviceId) {
    return request.post('/device/getDeviceById?id=' + deviceId);
}

//查询相机品牌
export function searchBrand(params) {
    return request.post('/device/searchBrand?' + objectToQueryString(params));
}
