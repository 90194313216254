<template>
  <div v-loading="isLoading" class="page">
    <div class="border-radius4 height50" style="background: #FAFAFA;display: flex;">
      <div style="background: #0768FD;color: white;display: flex;
            align-items: center;justify-content: center;cursor: pointer;"
           class="width110 font-size16 border-radius4004"
           @click="goBack">
        返回
      </div>
      <div v-if="motionType === '0'"
           style="color: black;font-weight: bold;display: flex; align-items: center;"
          class="font-size16 margin-left30">
        新增设备
      </div>
      <div v-else
          style="color: black;font-weight: bold;display: flex; align-items: center;"
          class="font-size16 margin-left30">
        编辑设备
      </div>
    </div>
      <div class="add-parking-basic">

        <easy-card title="基本信息">
          <el-form
              ref="form"
              :model="model"
              :rules="rules"
              label-width="200px"
              size="small"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="设备类型" prop="type" required>
                  <el-select
                      v-model="model.type"
                      placeholder="设备类型"
                      style="width: 100%"
                  >
                    <el-option label="视频桩" value="0"></el-option>
                    <el-option label="地磁" value="1"></el-option>
                    <el-option label="巡检车" value="2"></el-option>
                    <el-option label="PDA" value="3"></el-option>
                    <el-option label="高点" value="4"></el-option>
                    <el-option label="相机" value="5"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
              <el-form-item label="车场类型" prop="parkingLotType" required>
                <el-select
                    v-model="model.parkingLotType"
                    @change="changeParkingLotType"
                    placeholder="请选择车场类型"
                    style="width: 100%"
                >
                  <el-option label="路内" value="1"></el-option>
                  <el-option label="路外" value="0"></el-option>
                </el-select>
              </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="设备SN" prop="sn" required>
                  <el-input
                      v-model="model.sn"
                      placeholder="设备SN"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="车场" prop="parkingLotId" required>
                  <SuggestParking
                      :parking-lot-type="curParkingLotType"
                      @parkingName="onChangeParkingLot"
                      v-model="model.parkingLotId"
                      style="width: 100%"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="设备编号" prop="code" required>
                  <el-input
                      v-model="model.code"
                      placeholder="请输入设备编号"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item v-if="curParkingLotType == '1'"
                              label="泊位号"
                              prop="spaceCode">
                  <el-input
                      v-model="model.spaceCode"
                      placeholder="泊位号"
                  ></el-input>
                </el-form-item>

                <el-form-item v-else-if="curParkingLotType == '0'"
                              label="出入口"
                              prop="parkingLotGateId" required>
                  <el-select
                      v-model="model.parkingLotGateId"
                      clearable
                      placeholder="请选择出入口"
                      style="width: 100%"
                  >
                    <el-option
                        v-for="item in parkingGateList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="model.type==='5'">
              <el-col :span="12">
                <el-form-item label="相机品牌" prop="cameraBrand">
                  <el-select
                      v-model="model.cameraBrand"
                      placeholder="请选择相机品牌"
                      style="width: 100%"
                  >
                    <el-option v-for="(item, index) in cameraBrandList"
                               :key="index"
                               :label="item.brandName" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-button class="xxd-button_save" type="primary" @click="save"
            >保存
            </el-button>
          </el-form>
        </easy-card>
      </div>
  </div>
</template>

<script>
import {
  saveDevice,
  editDevice,
  getDeviceById,
  searchBrand,
} from "@/api/device";
import {
  checkToken,
} from "@/api/common";
import {MessageBox} from "element-ui";
import {getRegionList} from "@/api/user";
import SuggestParking from "@/components/SuggestParking.vue";
import {findParkingGateByParam} from "@/api/entranceMonitoring";

export default {
  name: "add-parking",
  components: {
    SuggestParking
  },

  data() {
    return {
      // 停车场类型 0 路外停车场 1.路内停车场
      curParkingLotType: '1',
      deviceId:'',
      motionType: '0',
      defaultRegion: [],
      parkingGateList: [],
      cameraBrandList: [],
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const {level, value} = node;
          console.log('node.value:', value)
          let regionId = level === 0 ? 0 : value;
          getRegionList({regionId: regionId})
              .then(({returnObject}) => {
                console.log('getRegionList:', returnObject)
                const nodes = returnObject.regionResultVoList.map(item => ({
                      value: item.id,
                      label: item.name,
                      leaf: item.level == 3,
                    })
                )
                resolve(nodes)
              })
        }
      },
      isLoading: false,
      rules: {
        type: [{required: true, message: "设备类型不能为空"}],
        code: [{required: true, message: "设备编码不能为空"}],
        sn: [{required: true, message: "设备SN不能为空"}],
        parkingLotId: [{required: true, message: "车场不能为空"}],
        parkingLotType: [{required: true, message: "车场类型不能为空"}],
        // spaceCode: [{required: true, message: "泊位号不能为空"}],
      },
      // 数据模型
      model: {
        id: '',
        type: '0',
        code:'',
        sn:'',
        parkingLotId: '',
        parkingLotType: '1',
        spaceCode: '',
        parkingLotGateId: '',
        cameraBrand: '',
      },
    };
  },

  methods: {
    getCameraBrand(){
      searchBrand().then((res) => {
        if (res && res.code === 30 && res.result) {
          this.cameraBrandList = res.returnObject
        }
      });
    },
    changeParkingLotType(value) {
      console.log(value)
      this.curParkingLotType = value
      this.model.parkingLotId = ''
      this.parkingGateList = []
    },
    onChangeParkingLot(item){
      this.curParkingLotType = item.parkingLotType
      if(item.parkingLotType == '1'){
        // 路内车场

      }else if(item.parkingLotType == '0'){
        // 路外车场
        findParkingGateByParam(
            {
              parkingLotId: item.id,
            }
        ).then((res) => {
          if (res && res.code === 30 && res.result) {
            this.parkingGateList = res.returnObject
          }
        });
      }
    },
    goBack(){
      var item =  {
        "fullPath": "/parking_manage/add?motionType=0",
        "hash": "",
        "meta": {
          "requiresAuth": true,
          "isAdmin": true,
          "icon": "el-icon-bell",
          "hidden": true,
          "title": "停车场信息维护"
        },
        "name": "add",
        "params": {},
        "path": "/parking_manage/add",
        "query": {
          "motionType": "0"
        }
      };
      this.$store.commit("app/removeRoute", item);
      this.$router.back();
    },
    async save() {
      await this.$refs.form.validate(async (valid) => {
        if (valid) {
          // debugger
          if(this.model.type === '5' && this.model.parkingLotType!=='0'){
            this.$message.error("相机只能在路外车场使用。");
            return
          }
          if(this.model.type === '5' && this.model.cameraBrand===''){
            this.$message.error("请选择相机品牌。");
            return
          }

          this.isLoading = true;
          if (this.model.id === '') {
            const res = await saveDevice(this.model);
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "设备添加成功!",
                type: "success",
              });
              this.$emit("success", res.returnObject);
              sessionStorage.setItem("save_device_success", "2");
              this.goBack();
            }
          } else {
            const res = await editDevice(this.model);
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "设备编辑成功!",
                type: "success",
              });
              this.$emit("success", res.returnObject);
              sessionStorage.setItem("save_device_success", "2");
              this.goBack();
            }
          }

        }
      });
    },
  },
  mounted() {
    checkToken()
        .then(async res => {
          console.log('checkToken', res)
          if (!res || res.code !== 30) {
            MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
              confirmButtonText: '重新登录',
              callback: () => {
                window.localStorage.clear()
                window.$vue.$router.push('/login')
              }
            });
          } else {
            this.getCameraBrand()
            const deviceId = this.$route.query.deviceId;
            this.motionType = this.$route.query.motionType;
            if (deviceId) {
              this.deviceId = deviceId;
              const res = await getDeviceById(this.deviceId);
              if (res && res.code === 30 && res.result) {
                this.model = res.returnObject
                this.model.type = this.model.type.toString()
                if (this.model.cameraBrand){
                  this.model.cameraBrand = this.model.cameraBrand.toString()
                }
                console.log(this.model)
              }
            }
          }
        })
  },
};
</script>

<style lang="less" scoped>
.add-parking-basic {
  background: white;
  border-radius: 5px;
  padding: 18px;
}

.tabs-wrap {
  position: relative;

  .back-button {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

    i {
      font-size: 18px;
    }
  }
}
</style>
